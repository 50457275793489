import { UserManager, User, UserManagerSettings, WebStorageStateStore, SigninPopupArgs, SignoutRedirectArgs, SignoutPopupArgs, SignoutRequest } from "oidc-client-ts";
import AuthManagerSettings from "@/data/auth/settings/AuthSettings";
import SecureStorage from "@/data/auth/SecureStorage";
import { getCallbackPath, firstLetterUppercase } from "@/helpers/OidcHelper";
import { identityUrl, uiUrl } from "@/Config";
import { DiscoveryDocumentStore } from "./discoveryStore";

const defaultAuthManagerSettings: Partial<AuthManagerSettings> = {
  userStore: new WebStorageStateStore({ store: new SecureStorage }),
  loadUserInfo: true,
  automaticSilentSignin: true
}

const sessionStorageKey = "auth-active-route";

export default class AuthManager {

  private static getAuthSettings(settings: AuthManagerSettings): UserManagerSettings {
    return {
      ...defaultAuthManagerSettings,
      ...settings
    };
  }

  public readonly automaticSilentRenew?: boolean;

  public readonly callbackPath: string | null;
  public readonly popupCallbackPath: string | null;
  public readonly silentCallbackPath: string | null;

  public readonly settings: AuthManagerSettings;
  private readonly userManager: UserManager;

  constructor(settings: AuthManagerSettings) {
    this.settings = AuthManager.getAuthSettings(settings);

    const discoveryStore = new DiscoveryDocumentStore();
    const discoveryDocument = discoveryStore.getCachedDocument();
    if (discoveryDocument != null) {
      this.settings.metadata = discoveryDocument.document;
    }

    this.userManager = new UserManager(this.settings);

    this.automaticSilentRenew = settings.automaticSilentRenew;
    this.callbackPath = getCallbackPath(settings.redirect_uri, uiUrl);
    this.popupCallbackPath = getCallbackPath(settings.popup_redirect_uri, uiUrl);
    this.silentCallbackPath = getCallbackPath(settings.silent_redirect_uri, uiUrl);
  }

  get events() {
    return this.userManager.events;
  }

  public saveActiveRoute(activeRoute: string) {
    sessionStorage.setItem(sessionStorageKey, activeRoute);
  }

  public getActiveRoute() {
    return sessionStorage.getItem(sessionStorageKey) ?? "/";
  }

  public clearSessionStorage() {
    sessionStorage.removeItem(sessionStorageKey);
  }

  public signinRedirect(args?: any) {
    return this.userManager.signinRedirect(args);
  }

  public signinSilent(args?: any) {
    return this.userManager.signinSilent(args);
  }

  public signinSilentCallback(url?: string) {
    return this.userManager.signinSilentCallback(url);
  }

  public signinRedirectCallback(url?: string) {
    return this.userManager.signinRedirectCallback(url);
  }
  
  public signinPopup(args?: SigninPopupArgs) {
    return this.userManager.signinPopup(args);
  }

  public signinPopupCallback(url?: string, keepOpen?: boolean) {
    return this.userManager.signinPopupCallback(url, keepOpen);
  }

  public addAccessTokenExpired(callback: () => void) {
    this.userManager.events.addAccessTokenExpired(callback);
  }

  public addAccessTokenExpiring(callback: () => void) {
    this.userManager.events.addAccessTokenExpiring(callback);
  }

  public storeUser(user: User) {
    return this.userManager.storeUser(user);
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public getUserOrDefault(): Promise<User | null> {
    return new Promise<User | null>(resolve => {
      this.getUser()
        .then((user) => resolve(user))
        .catch(() => resolve(null));
    });
  }

  public signout(args?: SignoutRedirectArgs) {
    return this.userManager.signoutRedirect(args);
  }

  public signoutCallback(url?: string) {
    return this.userManager.signoutRedirectCallback(url);
  }

  public signoutPopup(args?: SignoutPopupArgs) {
    return this.userManager.signoutPopup(args);
  }

  public signoutPopupCallback(url?: string, keepOpen?: boolean) {
    return this.userManager.signoutPopupCallback(url, keepOpen);
  }

  public removeUser() {
    return this.userManager.removeUser();
  }

  public clearStaleState() {
    return this.userManager.clearStaleState();
  }

  public startSilentRenew() {
    return this.userManager.startSilentRenew();
  }

  public stopSilentRenew() {
    return this.userManager.stopSilentRenew();
  }

  public addEventListener(eventName: string, eventListener: (...args: unknown[]) => void) {
    if (typeof eventListener !== 'function') {
      console.warn(`[addEventListener] "eventListener" is not a function. Aborting.`);
      return;
    }

    const functionName = `add${firstLetterUppercase(eventName)}`;
    const events = this.userManager.events as any;
    if (typeof events[functionName] === 'function') {
      events[functionName](eventListener);
    }
  }

  public removeEventListener(eventName: string, eventListener: (...args: unknown[]) => void) {
    if (typeof eventListener !== 'function') {
      console.warn(`[removeEventListener] "eventListener" is not a function. Aborting.`);
      return;
    }

    const functionName = `remove${firstLetterUppercase(eventName)}`;
    const events = this.userManager.events as any;
    if (typeof events[functionName] === 'function') {
      events[functionName](eventListener);
    }
  }

  public impersonate(email: string, retrunUrl?: string) {
    const impersonationUrl = new URL(`${identityUrl}/impersonate`);
    impersonationUrl.searchParams.append('email', email);
    impersonationUrl.searchParams.append('returnUrl', retrunUrl ?? window.location.origin);

    this.removeUser().then(() => {
      window.location.replace(impersonationUrl);
    });
  }

  public endImpersonation(retrunUrl?: string) {
    const impersonationUrl = new URL(`${identityUrl}/end-impersonation`);
    impersonationUrl.searchParams.append('returnUrl', retrunUrl ?? window.location.origin);

    this.removeUser().then(() => {
      window.location.replace(impersonationUrl);
    });
  }
}