import { User, SigninRedirectArgs, SignoutRedirectArgs, UserProfile } from 'oidc-client-ts';
import { ActionContext } from 'vuex';
import { Route } from 'vue-router';

import AuthManager from '@/data/auth/newAuthManager';
import { tokenExpiration, tokenIsExpired } from '@/helpers/OidcHelper';
import { actionTypes, mutationTypes } from './authTypes';

import {
  OrganizationAdministratorRole,
  IdentityServerAdministratorRole,
  claimTypes
} from '@/Constants';

export type OidcEventListener = (detail?: any) => void;

export interface AuthState {
  accessToken?: string;
  idToken?: string;
  refreshToken?: string;
  user?: UserProfile;
  expiresAt: number | null;
  scopes: string[] | null;
  isChecked: boolean;
  eventsAreBound: boolean;
  error: any;
}

export interface StoreSettings {
  namespaced?: boolean;
  isAuthenticatedBy?: keyof AuthState;
  removeUserWhenTokensExpire?: boolean;
  routeBase?: string;
  publicRoutePaths?: string[];
  isPublicRoute?: (route: any) => boolean;
  dispatchEventsOnWindow?: boolean;
  defaultSigninRedirectOptions?: SigninRedirectArgs;
  defaultSigninSilentOptions?: Record<string, any>;
  defaultSigninPopupOptions?: Record<string, any>;
}

export interface EventListeners {
  [key: string]: (payload?: any) => void
}

export type AuthActionContext = ActionContext<AuthState, any>;

export default (manager: AuthManager, storeSettings: StoreSettings = {}, eventListeners: EventListeners = {}) => {
  const state: AuthState = {
    expiresAt: null,
    scopes: null,
    isChecked: false,
    eventsAreBound: false,
    error: null
  };

  storeSettings = {
    namespaced: false,
    isAuthenticatedBy: 'accessToken',
    removeUserWhenTokensExpire: true,
    ...storeSettings
  };

  const isAuthenticated = (state: AuthState) => {
    return state[storeSettings.isAuthenticatedBy!];
  };

  const checkForRole = (user: UserProfile, role: string): boolean => {
    return Array.isArray(user.role) &&
      user.role.some(x => x === role) ||
      user.role === role;
  };

  const getClaim = (user: UserProfile, claimType: string): unknown => {
    return user[claimType];
  }

  const routeIsOidcCallback = (route: Route): boolean => {
    if (route.meta && route.meta.isOidcCallback) {
      return true;
    }

    if (!route.path) {
      return false;
    }

    const path = route.path.replace(/\/$/, '');
    if (path === manager.callbackPath || path === manager.popupCallbackPath || path === manager.silentCallbackPath) {
      return true;
    }

    return false;
  };

  const isPublic = (route: Route): boolean => {
    if (route.meta && route.meta.isPublic) {
      return true;
    }

    if (
      storeSettings.publicRoutePaths &&
      storeSettings.publicRoutePaths
        .map(path => path.replace(/\/$/, ''))
        .indexOf(route.path.replace(/\/$/, '')) > -1
    ) {
      return true;
    }

    if (
      storeSettings.isPublicRoute &&
      typeof storeSettings.isPublicRoute === 'function'
    ) {
      return storeSettings.isPublicRoute(route)
    }

    return false;
  };

  const getters = {
    isAuthenticated: (state: AuthState): boolean => {
      return isAuthenticated(state);
    },
    user: (state: AuthState): any => {
      return state.user;
    },
    accessToken: (state: AuthState): string | undefined => {
      return tokenIsExpired(state.expiresAt)
        ? undefined
        : state.accessToken;
    },
    accessTokenExpiration: (state: AuthState): number | null => {
      return state.expiresAt;
    },
    scopes: (state: AuthState): string[] | null => {
      return state.scopes;
    },
    idToken: (state: AuthState): string | undefined => {
      return storeSettings.removeUserWhenTokensExpire && tokenIsExpired(state.expiresAt)
        ? undefined
        : state.idToken;
    },
    idTokenExpiration: (state: AuthState): number | undefined => {
      return tokenExpiration(state.idToken);
    },
    refreshToken: (state: AuthState): string | undefined => {
      return tokenExpiration(state.refreshToken)
        ? undefined
        : state.refreshToken;
    },
    refreshTokenExpiration: (state: AuthState): number | undefined => {
      return tokenExpiration(state.refreshToken);
    },
    isChecked: (state: AuthState): boolean => {
      return state.isChecked;
    },
    error: (state: AuthState): any => {
      return state.error;
    },
    routeIsPublic: () => {
      return (route: Route): boolean => isPublic(route);
    },
    isImpersonating(state: AuthState): boolean {
      return !!state.user && getClaim(state.user, claimTypes.actorSub) != null;
    }
  };

  const actions = {
    async checkAccess(context: AuthActionContext, route: Route): Promise<boolean> {
      if (routeIsOidcCallback(route)) {
        console.log('is oidc callback')
        return true;
      }

      let hasAccess = true;
      let user: User | null = null;

      try {
        user = await manager.getUser();
      } catch {
        user = null;
      }

      const isAuthenticatedInStore = isAuthenticated(context.state);
      if (!user || user.expired) {
        const authenticateSilently = manager.settings.silent_redirect_uri && manager.settings.automaticSilentSignin;

        if (isPublic(route)) {
          if (isAuthenticatedInStore) {
            context.commit(mutationTypes.unsetAuth);
          }
          if (authenticateSilently) {
            try {
              await context.dispatch(actionTypes.authenticateSilent, { ignoreErrors: true });
            } catch (err) {
              console.error(err);
            }
          }
        } else {
          const authenticate = () => {
            if (isAuthenticatedInStore) {
              context.commit(mutationTypes.unsetAuth);
            }
            context.dispatch(actionTypes.authenticate, {
              redirectPath: route.fullPath
            })
          }

          if (authenticateSilently) {
            try {
              await context.dispatch(actionTypes.authenticateSilent, { ignoreErrors: true });
              const user2 = await manager.getUser()
              if (!user2 || user2.expired) {
                authenticate()
              }
              return !!user2
            } catch {
              authenticate()
              return false
            }
          }

          authenticate()
          hasAccess = false
        }
      } else {
        await context.dispatch(actionTypes.wasAuthenticated, user);
      }

      return hasAccess;
    },

    async authenticate(context: AuthActionContext, payload: any = {}): Promise<void> {
      if (typeof payload === 'string') {
        payload = { redirectPath: payload }
      }

      if (payload.redirectPath) {
        manager.saveActiveRoute(payload.redirectPath);
      } else {
        manager.clearSessionStorage();
      }

      const options: SigninRedirectArgs =
        payload.options || storeSettings.defaultSigninRedirectOptions || {}

      try {
        await manager.signinRedirect(options)
      } catch (err) {
        context.commit(mutationTypes.setError, errorPayload('authenticate', err))
      }
    },

    async signinCallback(context: AuthActionContext, url: string): Promise<string> {
      try {
        const user: User = await manager.signinRedirectCallback(url);
        await context.dispatch(actionTypes.wasAuthenticated, user);
        return manager.getActiveRoute();
      } catch (err) {
        context.commit(mutationTypes.setError, errorPayload('signinCallback', err));
        context.commit(mutationTypes.setIsChecked);
        throw err;
      }
    },

    async authenticateSilent(context: AuthActionContext, payload: any = {}): Promise<User | null> {
      const options = payload.options || storeSettings.defaultSigninSilentOptions || {}
      try {
        const user = await manager.signinSilent(options);
        await context.dispatch(actionTypes.wasAuthenticated, user);
        return user;
      } catch (err) {
        context.commit(mutationTypes.setIsChecked);
        if (payload.ignoreErrors) {
          return null
        } else {
          context.commit(mutationTypes.setError, errorPayload('authenticateSilent', err))
          throw err
        }
      }
    },

    async authenticatePopup(context: AuthActionContext, payload: any = {}): Promise<void> {
      const options = payload.options || storeSettings.defaultSigninPopupOptions || {}
      try {
        const user = await manager.signinPopup(options)
        await context.dispatch(actionTypes.wasAuthenticated, user);
      } catch (err) {
        context.commit(mutationTypes.setError, errorPayload('authenticatePopup', err))
      }
    },

    async signinPopupCallback(context: AuthActionContext, url: string): Promise<void> {
      try {
        await manager.signinPopupCallback(url)
      } catch (err) {
        context.commit(mutationTypes.setError, errorPayload('signinPopupCallback', err))
        context.commit(mutationTypes.setIsChecked)
        throw err
      }
    },

    async wasAuthenticated(context: AuthActionContext, user: User) {
      context.commit(mutationTypes.setAuth, user);

      if (!context.state.eventsAreBound) {
        manager.events.addAccessTokenExpired(() => {
          if (storeSettings.removeUserWhenTokensExpire) {
            context.commit(mutationTypes.unsetAuth);
          } else {
            context.commit(mutationTypes.unsetAccessToken);
          }
        });

        if (manager.settings.automaticSilentRenew) {
          manager.events.addAccessTokenExpiring(() => {
            context.dispatch(actionTypes.authenticateSilent)
              .catch(err => {
                dispatchCustomErrorEvent(
                  'automaticSilentRenewError',
                  errorPayload('wasAuthenticated', err)
                )
              });
          })
        }
        context.commit(mutationTypes.setEventsAreBound)
      }
      context.commit(mutationTypes.setIsChecked)
    },

    async storeUser(context: AuthActionContext, user: User): Promise<void> {
      try {
        await manager.storeUser(user)
        const newUser = await manager.getUser()
        if (newUser) {
          await context.dispatch(actionTypes.wasAuthenticated, newUser)
        }
      } catch (err) {
        context.commit(mutationTypes.setError, errorPayload('storeUser', err))
        context.commit(mutationTypes.setEventsAreBound)
        throw err
      }
    },

    async getUser(context: AuthActionContext): Promise<User | null> {
      const user = await manager.getUser()
      context.commit(mutationTypes.setUser, user)
      return user
    },

    addEventListener(
      context: AuthActionContext,
      payload: { eventName: string; eventListener: OidcEventListener }
    ) {
      manager.addEventListener(payload.eventName, payload.eventListener)
    },

    removeEventListener(
      context: AuthActionContext,
      payload: { eventName: string; eventListener: OidcEventListener }
    ) {
      manager.removeEventListener(payload.eventName, payload.eventListener)
    },

    async signout(context: AuthActionContext, payload?: SignoutRedirectArgs) {
      try {
        await manager.signout(payload);
        context.commit(mutationTypes.unsetAuth)
      } catch (err) {
        console.error(err);
      }
    },

    async signoutCallback() {
      try {
        await manager.signoutCallback()
      } catch (err) {
        console.error(err);
      }
    },

    async signoutPopup(context: AuthActionContext, payload?: any) {
      try {
        await manager.signoutPopup(payload);
        context.commit(mutationTypes.unsetAuth)
      } catch (err) {
        console.error(err);
      }
    },

    async signoutPopupCallback() {
      try {
        await manager.signoutPopupCallback();
      } catch (err) {
        console.error(err);
      }
    },

    async removeUser(context: AuthActionContext): Promise<void> {
      await manager.removeUser();
      context.commit(mutationTypes.unsetAuth);
    },

    async clearStaleState() {
      await manager.clearStaleState();
    },

    async startSilentRenew() {
      await manager.startSilentRenew();
    },

    async stopSilentRenew() {
      await manager.stopSilentRenew();
    },

    isAdmin(context: AuthActionContext) {
      return context.state.user && (
        checkForRole(context.state.user, OrganizationAdministratorRole)
        || checkForRole(context.state.user, IdentityServerAdministratorRole)
      );
    },

    isOrganizationAdmin(context: AuthActionContext) {
      return context.state.user && checkForRole(context.state.user, OrganizationAdministratorRole);
    },

    isIdentityAdmin(context: AuthActionContext) {
      return context.state.user && checkForRole(context.state.user, IdentityServerAdministratorRole);
    },

    getOrganizationId(context: AuthActionContext) {
      if (!context.state.user) {
        return '';
      }

      return getClaim(context.state.user, claimTypes.organizationId);
    },

    impersonate(context: AuthActionContext, email: string) {
      manager.impersonate(email);
    },

    endImpersonation(context: AuthActionContext) {
      manager.endImpersonation();
    }
  }

  const mutations = {
    setAuth(state: AuthState, user: User) {
      state.idToken = user.id_token;
      state.accessToken = user.access_token;
      state.refreshToken = user.refresh_token;
      state.expiresAt = user.expires_at
        ? user.expires_at * 1000
        : null;
      state.user = user.profile;
      state.scopes = (user as any).scopes || null;
      state.error = null;
    },
    setUser(state: AuthState, user: User | null) {
      state.user = user
        ? user.profile
        : undefined;
      state.expiresAt = user && user.expires_at
        ? user.expires_at * 1000
        : null;
    },
    unsetAuth(state: AuthState) {
      state.idToken = undefined;
      state.accessToken = undefined;
      state.refreshToken = undefined;
      state.user = undefined;
    },
    unsetAccessToken(state: AuthState) {
      state.accessToken = undefined;
      state.refreshToken = undefined;
    },
    setIsChecked(state: AuthState) {
      state.isChecked = true;
    },
    setEventsAreBound(state: AuthState) {
      state.eventsAreBound = true;
    },
    setError(state: AuthState, payload: { error: any }) {
      state.error = payload.error;
      dispatchCustomErrorEvent('authError', payload);
    }
  };

  const errorPayload = (context: string, error: any) => {
    return {
      context,
      error: error && error.message ? error.message : error
    }
  }

  const dispatchCustomErrorEvent = (eventName: string, payload: any) => {
    console.info(`${eventName}:${payload}`);
  }

  return {
    state,
    getters,
    actions,
    mutations
  };
};

