import axios from 'axios';
import { OidcMetadata } from 'oidc-client-ts';

export interface CachedDiscoveryDocument {
  document: OidcMetadata;
  timestamp: number;
  ttl: number; // Time to live in milliseconds.
}

export class DiscoveryDocumentStore {
  private storageKey: string;
  private ttl: number;

  constructor(storageKey: string = 'discovery_document', ttl: number = 3600 * 1000) {
    this.storageKey = storageKey;
    this.ttl = ttl;
  }

  public getCachedDocument(): CachedDiscoveryDocument | null {
    const raw = localStorage.getItem(this.storageKey);
    if (!raw) {
      return null;
    }
    try {
      const cached = JSON.parse(raw) as CachedDiscoveryDocument;
      if (this.isDocumentExpired(cached)) {
        // Remove expired document from storage.
        localStorage.removeItem(this.storageKey);
        return null;
      }
      return cached;
    } catch (e) {
      console.error('Failed to parse cached discovery document:', e);
      return null;
    }
  }

  private setCachedDocument(document: OidcMetadata): void {
    const cached: CachedDiscoveryDocument = {
      document,
      timestamp: Date.now(),
      ttl: this.ttl,
    };
    localStorage.setItem(this.storageKey, JSON.stringify(cached));
  }

  private isDocumentExpired(cached: CachedDiscoveryDocument): boolean {
    return (Date.now() - cached.timestamp) > cached.ttl;
  }

  public async preloadDiscoveryDocument(authority: string): Promise<OidcMetadata> {
    const cached = this.getCachedDocument();
    if (cached) {
      console.log('Using cached discovery document.');
      return cached.document;
    }

    const url = `${authority}/.well-known/openid-configuration`;
    console.log(`Fetching discovery document from ${url}`);

    const response = await axios.get<OidcMetadata>(url);
    const document = response.data;

    this.setCachedDocument(document);
    return document;
  }
}