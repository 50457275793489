<template>
  <div class="logo-block">
    <div class="logo">
      <img src="/img/logo/logo.svg" class="qualer-logo" alt="Qualer Logo">
    </div>
    <div class="service-name-block">
      <span class="service-name">Qualer.Identity</span>
    </div>
    <div style="clear: both;"></div>
    <div class="loading-animation">
      <span class="loading-message">
        User data retrieval
      </span>
    </div>
  </div>
</template>


<script lant="ts">
import { defineComponent, onMounted } from 'vue';
import { useStore, useRouter } from '@/helpers/vue/composition';
import { actionTypes } from '@/store/modules/newAuth';

export default defineComponent({
  name: 'OidcCallback',
  setup() {
    const store = useStore();
    const router = useRouter();

    onMounted(async ()=> {
      store.dispatch(actionTypes.signinCallback).then(redirectPath => {
        router.push(redirectPath);
      }).catch(error => {
        window.console.log(error);
        router.push("/forbidden");
      });
    });
  }
})
</script>

<style>
@import url('~/public/loading.css');

.loading-message {
  font-size: 1em;
  font-weight: 400;
}
</style>