import { AsyncStorage } from "oidc-client-ts";
import SecureLS from "secure-ls";

export default class SecureStorage implements AsyncStorage {
    private readonly storage: SecureLS;

    constructor() {
        this.storage = new SecureLS({ isCompression: false });
    }

    get length(): Promise<number> {
        return Promise.resolve(this.storage.getAllKeys().length)
    }
    
    clear(): Promise<void> {
        return Promise.resolve(this.storage.clear());
    }

    getItem(key: string): Promise<string | null> {
        return Promise.resolve(this.storage.get(key));
    }

    key(index: number): Promise<string | null> {
        const keys = this.storage.getAllKeys();
        const key = index in keys 
            ? keys[index] 
            : null;
        return Promise.resolve(key);
    }

    removeItem(key: string): Promise<void> {
        return Promise.resolve(this.storage.remove(key));
    }

    setItem(key: string, value: string): Promise<void> {
        return Promise.resolve(this.storage.set(key, value));
    }
}
