import { Store } from "vuex";
import { NavigationGuard } from "vue-router";
import { actionTypes } from "@/store/modules/newAuth";

export default (store: Store<any>): NavigationGuard =>
    async (to, form, next) => {
        if (await store.dispatch(actionTypes.checkAccess, to)) {
            next();
        }
    };
