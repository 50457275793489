
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ name: "dropdown-item" })
export default class DropdownItem extends Vue {
  @Prop() public readonly icon?: string;
  @Prop({ default: false }) public readonly disabled?: boolean;

  private onClick() {
    this.$emit("click");
  }
}
