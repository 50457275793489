export const moduleName = 'auth';

export const getterTypes = {
  isAuthenticated: 'isAuthenticated',
  accessToken: 'accessToken',
  accessTokenExpiration: 'accessTokenExpiration',
  idToken: 'idToken',
  idTokenExpiration: 'idTokenExpiration',
  refreshToken: 'refreshToken',
  refreshTokenExpiration: 'refreshTokenExpiration',
  isChecked: 'isChecked',
  user: 'user',
  isImpersonating: 'isImpersonating',
  error: 'error',
  isRoutePublic: 'isRoutePublic'
};

export const actionTypes = {
  checkAccess: 'checkAccess',
  authenticate: 'authenticate',
  signinCallback: 'signinCallback',
  authenticateSilent: 'authenticateSilent',
  authenticatePopup: 'authenticatePopup',
  signinPopupCallback: 'signinPopupCallback',
  wasAuthenticated: 'wasAuthenticated',
  storeUser: 'storeOidcUser',
  signout: "signout",
  signoutCallback: 'signoutCallback',
  signoutPopup: 'signoutPopup',
  signoutPopupCallback: 'signoutPopupCallback',
  removeUser: 'removeUser',
  clearStaleState: 'clearStaleState',
  startSilentRenew: 'startSilentRenew',
  stopSilentRenew: 'stopSilentRenew',

  isAdmin: 'isAdmin',
  isOrganizationAdmin: 'isOrganizationAdmin',
  isIdentityAdmin: 'isIdentityAdmin',
  getOrganizationId: 'getOrganizationId',

  impersonate: 'impersonate',
  endImpersonation: 'endImpersonation'
};

export const mutationTypes = {
  setAuth: 'setAuth',
  unsetAuth: 'unsetAuth',
  unsetAccessToken: 'unsetAccessToken',
  setUser: 'setUser',
  setIsChecked: 'setIsChecked',
  setEventsAreBound: 'setEventsAreBound',
  setError: 'setError'
};