import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "@/store/State";

import { permission } from "@/store/modules/permission";

import createAuthStore from '@/store/modules/newAuth';
import { settings } from "@/data/auth/configs/oidcConfig";

Vue.use(Vuex);

const auth = createAuthStore(settings);

const options: StoreOptions<RootState> = {
  state: {},
  modules: {
    auth,
    permission
  }
};

export default new Vuex.Store(options);
