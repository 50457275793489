import { Module } from 'vuex';
import { RootState } from '@/store/State';

import createAuthStore, { StoreSettings, EventListeners, AuthState } from './createAuthStore';
import { 
  moduleName, 
  getterTypes as authGetterTypes, 
  actionTypes as authActionTypes, 
  mutationTypes as authMutationTypes 
} from './authTypes';
import AuthManagerSettings from '@/data/auth/settings/AuthSettings';
import AuthManager from '@/data/auth/newAuthManager';

import { addPrefixAndFreezeInPlace } from '@/helpers/OidcHelper';

const getterTypes = addPrefixAndFreezeInPlace(`${moduleName}/`, { ...authGetterTypes });
const actionTypes = addPrefixAndFreezeInPlace(`${moduleName}/`, { ...authActionTypes });
const mutationTypes = addPrefixAndFreezeInPlace(`${moduleName}/`, { ...authMutationTypes });

export {
  moduleName,
  getterTypes,
  actionTypes,
  mutationTypes
};

export default (settings: AuthManagerSettings, storeSettings: StoreSettings = {}, eventListeners: EventListeners = {}) : Module<AuthState, RootState> => {
  const manager = new AuthManager(settings);
  const store = createAuthStore(manager, storeSettings, eventListeners);
  
  return {
    namespaced: true,
    actions: store.actions,
    mutations: store.mutations,
    getters: store.getters,
    state: store.state
  };
}