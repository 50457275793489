import Vue from "vue";
import Router from "vue-router";
import {
  OrganizationAdministratorRole,
  IdentityServerAdministratorRole
} from "@/Constants";

import createRouterMiddleware from "@/middlewares/RouterMiddleware";
import store from "@/store";
import OidcCallback from "@/views/auth/OidcCallback.vue";
import { oktaConstants } from "./data/identityProviders/okta/oktaContstants";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/callback",
      name: "oidcCallback",
      component: OidcCallback,
      meta: {
        isOidcCallback: true
      }
    },
    {
      path: "/forbidden",
      name: "forbidden",
      component: () => import("./views/Forbidden.vue")
    },
    {
      path: "/version",
      name: "version",
      component: () => import("./views/Version.vue")
    },
    {
      path: "/redirect",
      name: "redirect",
      component: () => import("./views/Redirect.vue")
    },
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
    },
    {
      path: "/audit/sign-ins",
      name: "sign-ins",
      component: () => import("./pages/logs/SignInLogsPage.vue")
    },
    {
      path: "/organization",
      name: "organizationAdmin",
      component: () => import("./views/OrganizationAdmin/Admin.vue"),
      meta: {
        role: OrganizationAdministratorRole
      },
      redirect: "/organization/users",
      children: [
        {
          path: "/organization/sign-ins",
          name: "myOrganizattionSignIns",
          component: () => import("./pages/logs/SignInLogsPage.vue")
        },
        {
          path: "/organization/users/:userId/sign-ins",
          name: "myOrganizationUserSignIns",
          props: true,
          component: () => import("./pages/logs/SignInLogsPage.vue")
        },
        {
          path: "/organization/dashboard",
          name: "myOrganizationDashboard",
          component: () => import("./views/Organization/dashboard/Dashboard.vue")

        },
        {
          path: "/organization/users",
          name: "organizationUsers",
          component: () => import("./views/Shared/Users.vue")
        },
        {
          path: "/organization/users/:userId",
          name: "identityUserProfile",
          props: true,
          component: () => import("./views/Shared/UserProfile.vue")
        },
        {
          path: "/organization/groups",
          name: "organizationGroups",
          component: () => import("./views/Organization/groups/Groups.vue")
        },
        {
          path: "/organization/groups/:groupId/members",
          name: "organizationGroupMembers",
          props: (item) => ({
            groupId: item.params.groupId,
            canModified: item.query.canModified,
            groupName: item.query.groupName
          }),
          component: () => import("./views/Organization/groups/groupMembers/GroupMembers.vue")
        },
        {
          path: "groups/:groupId/permissions",
          name: "organizationGroupPermissions",
          props: (item) => ({
            groupId: item.params.groupId,
            groupName: item.query.groupName,
            resourceId: item.query.resourceId,
            applicationId: item.query.applicationId
          }),
          component: () => import("./views/permissions/GroupPermissions.vue")
        },
        {
          path: "/organization/okta",
          name: "organizationOkta",
          component: () =>
            import("./views/Shared/identityProviders/OktaConfiguration.vue")
        },
        {
          path: "/organization/secureauth",
          name: "organization/SecureAuth",
          component: () =>
            import("./views/Shared/identityProviders/SecureAuthConfiguration.vue")
        },
        {
          path: "/organization/cyber-arc",
          name: "organization/CyberArc",
          props: true,
          component: () =>
            import("./views/Shared/identityProviders/CyberArcConfiguration.vue")
        },
        {
          path: "/organization/generic",
          name: "organizationGeneric",
          component: () =>
            import("./views/Shared/identityProviders/GenericConfiguration.vue")
        },
        {
          path: "saml/generic",
          name: "genericSaml",
          props: true,
          component: () =>
            import("./views/Shared/identityProviders/GenericSamlConfiguration.vue")
        },
        {
          path: "azure",
          name: "azure",
          props: true,
          component: () => import("./views/Shared/identityProviders/AzureConfiguration.vue")
        },
        {
          path: "/organization/applications",
          name: "organizationApplications",
          props: true,
          component: () => import("./views/Shared/Applications.vue")
        }
      ]
    },
    {
      path: "/organizations",
      name: "identityAdmin",
      component: () => import("./views/IdentityAdmin/Admin.vue"),
      meta: {
        role: IdentityServerAdministratorRole
      },
      redirect: "/organizations",
      children: [
        {
          path: "/organizations",
          name: "organizations",
          component: () =>
            import("./views/IdentityAdmin/Organizations.vue")
        },
        {
          path: "/resources",
          name: "resources",
          component: () => import("./views/IdentityAdmin/resources/Index.vue")
        },
        {
          path: "/apps",
          name: "apps",
          component: () => import("./views/IdentityAdmin/applicationTypes/Index.vue")
        },
        {
          path: "/apps/:appTypeId/routes",
          name: "routes",
          props: true,
          component: () => import("./views/404.vue")

        },
        {
          path: "/identity-providers/monitoring",
          name: "IdentityProviderMonitoring",
          component: () => import("./pages/identityProviders/IdentityProviderMonitoringPage.vue")
        },
        {
          path: "/roles/super-admins",
          name: "super-admin-manager",
          component: () => import("./views/roles/superAdminManager/Index.vue")
        },
        {
          path: "/organization/:organizationId",
          name: "Organization",
          props: true,
          component: () => import("./views/Organization/Index.vue"),
          children: [
            {
              path: "sign-ins",
              name: "organizattionSignIns",
              props: true,
              component: () => import("./pages/logs/SignInLogsPage.vue")
            },
            {
              path: "dashboard",
              name: "organizationDashboard",
              props: true,
              component: () => import("./views/Organization/dashboard/Dashboard.vue")
            },
            {
              path: "users/:userId/sign-ins",
              name: "organizationUserSignIns",
              props: true,
              component: () => import("./pages/logs/SignInLogsPage.vue")
            },
            {
              path: "okta",
              name: "identityOkta",
              props: true,
              component: () =>
                import("./views/Shared/identityProviders/OktaConfiguration.vue")
            },
            {
              path: "okta/logs",
              name: "OktaLogs",
              props: (x) => ({
                organizationId: x.params.organizationId || "",
                provider: oktaConstants.scheme
              }),
              component: () => import("./components/logs/LogViewer.vue"),
            },
            {
              path: "secureauth",
              name: "identitySecureAuth",
              props: true,
              component: () =>
                import("./views/Shared/identityProviders/SecureAuthConfiguration.vue")
            },
            {
              path: "cyber-arc",
              name: "identityCyberArc",
              props: true,
              component: () =>
                import("./views/Shared/identityProviders/CyberArcConfiguration.vue")
            },
            {
              path: "generic",
              name: "identityGeneric",
              props: true,
              component: () =>
                import("./views/Shared/identityProviders/GenericConfiguration.vue")
            },
            {
              path: "saml/generic",
              name: "identityGenericSaml",
              props: true,
              component: () =>
                import("./views/Shared/identityProviders/GenericSamlConfiguration.vue")
            },
            {
              path: "azure",
              name: "identityAzure",
              props: true,
              component: () => import("./views/Shared/identityProviders/AzureConfiguration.vue")
            },
            {
              path: "users",
              name: "identityUsers",
              props: true,
              component: () =>
                import("./views/Shared/Users.vue")
            },
            {
              path: "users/:userId",
              name: "userProfile",
              props: true,
              component: () => import("./views/Shared/UserProfile.vue")
            },
            {
              path: "groups",
              name: "identityGroups",
              props: true,
              component: () => import("./views/Organization/groups/Groups.vue")
            },
            {
              path: "groups/:groupId/permissions",
              name: "groupPermissions",
              props: (item) => ({
                organizationId: item.params.organizationId || "",
                groupId: item.params.groupId,
                groupName: item.query.groupName,
                resourceId: item.query.resourceId,
                applicationId: item.query.applicationId
              }),
              component: () => import("./views/permissions/GroupPermissions.vue")
            },
            {
              path: "groups/:groupId/members",
              name: "groupMembers",
              props: (item) => ({
                organizationId: item.params.organizationId,
                groupId: item.params.groupId,
                canModified: item.query.canModified,
                groupName: item.query.groupName
              }),
              component: () => import("./views/Organization/groups/groupMembers/GroupMembers.vue")
            },
            {
              path: "applications",
              name: "applications",
              props: true,
              component: () => import("./views/Shared/Applications.vue")
            },
            {
              path: "applications/:appId/access",
              name: "application-access",
              props: true,
              component: () => import("./views/IdentityAdmin/applicationAccess/Index.vue")
            },
            {
              path: "identityProviders",
              name: "identityProviders",
              props: true,
              component: () => import("./views/IdentityAdmin/IdentityProviders.vue")
            },
            {
              path: "security",
              name: "security",
              props: true,
              component: () => import("./views/Organization/security/OrganizationSecurityPage.vue")
            },
          ]
        }
      ]
    }
  ]
});

router.beforeEach(createRouterMiddleware(store));

export default router;
