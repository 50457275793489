import Vue from "vue";
import Toasted from "vue-toasted";
import VModal from "vue-js-modal";
import VeeValidate from "vee-validate";
// @ts-ignore
import VueCropper from "vue-cropper";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";
import "./middlewares/AxiosInterceptors";
import "./middlewares/ErrorHandler";
import * as components from "./components";
import closeModalOnEscape from "./plugins/closeModalOnEscape";
import store from "@/store";

import VTooltip from "v-tooltip";
import "./plugins/scss/index.scss";

import VueMoment from "vue-moment";

import { BootstrapVue } from 'bootstrap-vue';

import "vue-easytable/libs/theme-default/index.css";
import "vue-easytable/libs/font/iconfont.css";

import { identityUrl } from "@/Config";
import { DiscoveryDocumentStore } from "./data/auth/discoveryStore";

async function initialize() {
  const discoveryStore = new DiscoveryDocumentStore();
  await discoveryStore.preloadDiscoveryDocument(identityUrl!);

  Vue.use(VModal, { dynamic: true, dialog: true, dynamicDefaults: { clickToClose: false } });
  Vue.use(closeModalOnEscape);

  Vue.use(Toasted, {
    duration: 2500,
    containerClass: "toasts-container"
  });

  Vue.use(VeeValidate, {
    classes: true,
    classNames: {
      invalid: "is-invalid"
    }
  });

  Vue.use(BootstrapVue);
  Vue.use(VueCropper);
  Vue.use(VTooltip);
  Vue.use(VueMoment);

  Object.keys(components).forEach((key) => Vue.component(key, (components as any)[key]));

  Vue.config.productionTip = false;

  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount("#app");
}

initialize();