import AuthManager from "@/data/auth/newAuthManager";
import { settings } from "@/data/auth/configs/oidcConfig";

type MergeObjects<T extends object[]> = 
  T extends [infer First, ...infer Rest]
    ? First extends object
      ? Rest extends object[]
        ? First & MergeObjects<Rest>
        : First
      : {}
    : {};

export function objectAssign<T extends object[]>(...objects: T): MergeObjects<T> {
  return objects.reduce((acc, obj) => ({ ...acc, ...obj }), {}) as MergeObjects<T>;
}

export function addPrefixAndFreezeInPlace<T extends Record<string, string>>(
    prefix: string,
    obj: T
): T {
    const modifiedObject = obj as any;
    for (const key in obj) {
        modifiedObject[key] = prefix + obj[key];
    }

return Object.freeze(obj);
}

export const parseJwt = (token: string) => {
    try {
        const base64 = token
            .split(".")[1]
            .replace("-", "+")
            .replace("_", "/");
        return JSON.parse(window.atob(base64));
    } catch (error) {
        return {};
    }
};

export const firstLetterUppercase = (str: string): string => {
    return str && str.length > 0 
        ? str.charAt(0).toUpperCase() + str.slice(1) 
        : '';
  };
  
  export const camelCaseToSnakeCase = (str: string): string => {
    return str.replace(/([A-Z])/g, '_$1').toLowerCase();
  };

export const tokenExpiration = (token: string | undefined): number | undefined => {
    if (!token) {
        return undefined;
    }

    const jwtToken = parseJwt(token);
    return jwtToken.exp
        ? jwtToken.exp * 1000
        : undefined;
};

export function tokenIsExpired(expiresAt?: number | null): boolean {
  if (typeof expiresAt === 'number') {
    return expiresAt < new Date().getTime()
  }
  return false
}

export const getCallbackPath = (callbackUri?: string, url: string = "/"): string | null => {
  if (!callbackUri) {
      return null;
  }

  return callbackUri.slice(callbackUri.indexOf(url) + url.length).replace(/\/$/, '');
};

export const createAuthManager = (): AuthManager => {
  return new AuthManager(settings);
};

export const silentSigninCallback = (): Promise<void> => {
  return createAuthManager().signinSilentCallback();
}