import { getCurrentInstance } from 'vue';
import VueRouter from 'vue-router';
import { Store } from 'vuex';
import { VModal } from '@/types/vModal';
import { Toasted } from 'vue-toasted';

export const currentInstance = () : { proxy: Vue } => {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error('must be called in setup');
  }

  return vm;
};

export const useModal = (): VModal => {
  const vm = currentInstance();
  return vm.proxy.$modal;
}

export const useToasted = (): Toasted => {
  const vm = currentInstance();
  return vm.proxy.$toasted;
}

export const useStore = (): Store<any> => {
  const vm = currentInstance();
  return vm.proxy.$store;
};

export const useRouter = (): VueRouter => {
  return currentInstance().proxy.$router;
}