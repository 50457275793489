import axios from "axios";
import store from "@/store";
import { getterTypes } from "@/store/modules/newAuth";

axios.interceptors.request.use(req => {
  req.headers.Authorization = "Bearer " + store.getters[getterTypes.accessToken];

  if (req.method === "post" || req.method === "put" || req.method === "patch") {
    req.headers.Accept = "application/json";

    if (!req.headers["Content-Type"]) {
      req.headers["Content-Type"] = "application/json";
    }
  }

  return req;
});
